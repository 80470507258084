<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Calculate the
        <stemble-latex content="$\text{pH}$" />
        of the buffer formed by mixing
        <stemble-latex content="$65.0\,\text{mL}$" />
        of
        <stemble-latex content="$0.0610\,\text{M}\>$" />
        <chemical-latex content="NaH2PO4" />
        with
        <stemble-latex content="$44.0\,\text{mL}$" />
        of
        <stemble-latex content="$0.112\,\text{M}$" />
        <chemical-latex content="NaOH" />
        .
      </p>
      <p class="mb-2">
        (
        <chemical-latex content="H3PO4:" />
        <stemble-latex
          content="$\>\text{K}_\text{a1} = 7.11\,\text{x}\,10^{-3}; \text{K}_\text{a2} = 6.32\,\text{x}\,10^{-8}; \text{K}_\text{a3} = 4.5\,\text{x}\,10^{-13}$"
        />
        )
      </p>

      <calculation-input
        v-model="inputs.input1"
        class="mb-5"
        prepend-text="$\text{pH}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUPEI2210A6Q3',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
};
</script>
